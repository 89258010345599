<template>
  <vc-loading v-if="loading" class="mt-5">Loading Users...</vc-loading>
  <div v-else-if="vendor.isOwner">
    <b-row class="mt-5">
      <b-col cols="12" :lg="accountOwners.length > 0 ? 5 : 12">
        <h4>Account Owners</h4>

        <div class="platter mb-4">
          <vc-editor-table
            :can-edit="isSystemAdmin"
            :can-delete="isSystemAdmin && accountOwners.length > 1"
            :items="accountOwners"
            :sortable="false"
            item-name="Account Owner"
            title-field="fullName"
            desc-field="key"
            @add="addOwner"
            @edit="editOwner"
            @delete="deleteItem"
          />

          <div v-if="isSystemAdmin && hasAccountOwner" class="text-center mt-3">
            <b-button variant="primary" @click="addOwner">
              <strong>Add Account Owner</strong>
            </b-button>
          </div>

          <div
            v-if="!isSystemAdmin && hasAccountOwner"
            class="text-center text-muted mt-3"
          >
            <small>To change account owners, please contact us.</small>
          </div>
        </div>
      </b-col>
      <b-col v-if="accountOwners.length > 0">
        <h4>Designated Users</h4>

        <div class="platter">
          <vc-editor-table
            :items="designatedUsers"
            item-name="Designated User"
            title-field="fullName"
            desc-field="key"
            :sortable="false"
            @add="addDesignated"
            @edit="editDesignated"
            @delete="deleteItem"
          />

          <div v-if="hasDesignatedUsers" class="text-center mt-3">
            <b-button variant="primary" @click="addDesignated">
              <strong>Add Designated User</strong>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal
      id="user-modal"
      no-fade
      centered
      :title="modalModeLabel"
      :ok-disabled="!modalMeetsRequirements"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      @ok="save"
    >
      <b-form-group label="Email (Username)">
        <b-input
          type="email"
          v-model="modalUsername"
          :disabled="!!selectedUser"
        />
      </b-form-group>
      <b-row>
        <b-col>
          <b-form-group label="First Name">
            <b-input v-model="modalFirstName" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Last Name">
            <b-input v-model="modalLastName" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group v-if="isSystemAdmin">
        <b-check switch :checked="true" v-model="modalOwner">
          Is Account Owner
        </b-check>
      </b-form-group>

      <b-card v-if="!modalOwner" bg-variant="light" class="mt-2">
        <h6 class="accent mb-3"><strong>Permissions</strong></h6>
        <b-form-group>
          <b-check switch :checked="true" disabled>
            Access {{ $store.getters.appName }}
          </b-check>
        </b-form-group>
        <b-form-group>
          <b-check switch :checked="true" v-model="modalCanEdit">
            Edit {{ vendor.name }}
          </b-check>
        </b-form-group>
        <b-form-group class="mb-0">
          <b-check switch :checked="true" v-model="modalCanReport">
            View Reports for {{ vendor.name }}
          </b-check>
        </b-form-group>
      </b-card>
    </b-modal>
  </div>
  <h5 v-else class="mt-4 text-center text-muted">
    Only the account owner can maintain users for this vendor.
  </h5>
</template>

<script>
export default {
  name: "VendorUsers",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      users: [],
      loading: true,
      selectedUser: undefined,
      modalUsername: undefined,
      modalFirstName: undefined,
      modalLastName: undefined,
      modalCanEdit: false,
      modalCanReport: false,
      modalOwner: false
    };
  },
  computed: {
    accountOwners() {
      return this.users.filter(i => i.isOwner);
    },
    designatedUsers() {
      return this.users.filter(i => !i.isOwner);
    },
    hasAccountOwner() {
      return this.accountOwners?.length ?? 0 > 0;
    },
    hasDesignatedUsers() {
      return this.designatedUsers?.length ?? 0 > 0;
    },
    modalModeLabel() {
      return `${!this.selectedUser ? "Add" : "Edit"} ${
        this.modalOwner ? "Account Owner" : "User"
      }`;
    },
    modalMeetsRequirements() {
      //TODO: Check for valid email
      return this.modalUsername && this.modalFirstName && this.modalLastName;
    },
    isSystemAdmin() {
      return this.$store.state.currentUser.systemAdmin;
    }
  },
  watch: {
    vendor() {
      this.fetchData();
    },
    selectedUser() {
      this.modalFirstName = this.selectedUser?.firstName;
      this.modalLastName = this.selectedUser?.lastName;
      this.modalUsername = this.selectedUser?.key;
      this.modalCanEdit = this.selectedUser?.canEdit;
      this.modalCanReport = this.selectedUser?.canReport;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    addDesignated() {
      this.modalOwner = false;
      this.add();
    },
    addOwner() {
      this.modalOwner = true;
      this.add();
    },
    add() {
      this.selectedUser = undefined;
      this.$bvModal.show("user-modal");
    },
    editDesignated(i) {
      this.modalOwner = false;
      this.edit(i);
    },
    editOwner(i) {
      this.modalOwner = true;
      this.edit(i);
    },
    edit(i) {
      this.selectedUser = i;
      this.$bvModal.show("user-modal");
    },
    save() {
      const data = {
        vendorKey: this.vendor.key,
        userKey: this.modalUsername,
        firstName: this.modalFirstName,
        lastName: this.modalLastName,
        canEdit: this.modalCanEdit,
        canReport: this.modalCanReport,
        owner: this.modalOwner
      };
      const method = this.selectedUser ? "editVendorUser" : "addVendorUser";
      this.$store
        .dispatch(method, data)
        .then(
          d => {
            this.selectedUser = undefined;
            this.modalUsername = this.modalFirstName = this.modalLastName = this.modalCanReport = this.modalCanEdit = undefined;
            this.users = this.processUsers(d);
          },
          e => {
            this.showError(e);
          }
        )
        .finally(() => (this.loading = false));
    },
    deleteItem(i) {
      const data = {
        vendorKey: this.vendor.key,
        userKey: i.key
      };
      this.$store
        .dispatch("deleteVendorUser", data)
        .then(
          d => {
            this.users = this.processUsers(d);
          },
          e => {
            this.showError(e);
          }
        )
        .finally(() => (this.loading = false));
    },
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("fetchVendorUsersByKey", this.vendor.key)
        .then(d => {
          this.users = this.processUsers(d);
        })
        .finally(() => (this.loading = false));
    },
    processUsers(d) {
      return (d ?? []).map(i => {
        return { ...i, fullName: i.firstName + " " + i.lastName };
      });
    },
    showError(e) {
      this.$bvModal.msgBoxOk(e.message, {
        title: "Error",
        centered: true,
        noFade: true
      });
    }
  }
};
</script>

<style scope lang="scss">
</style>