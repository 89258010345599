<template>
  <div>
    <form
      v-if="!src"
      ref="imageUploadForm"
      @submit.prevent="uploadImage"
      enctype="multipart/form-data"
    >
      <b-form-group :label="title">
        <b-form-file
          name="image"
          placeholder="Choose an image or drop here"
          accept="image/*"
          @input="selectedFile"
          :disabled="uploading"
        />
      </b-form-group>

      <input type="hidden" name="vendorKey" :value="vendorKey" />
      <input type="hidden" name="kind" :value="kind" />

      <b-button type="submit" class="d-none" ref="uploadBtn">Upload</b-button>

      <vc-loading v-if="uploading">Uploading Image...</vc-loading>
    </form>

    <b-form-group v-else :label="title">
      <div class="text-center">
        <b-img v-if="!rounded" fluid :src="src" class="px-4" />
        <div
          v-else
          class="round mx-auto"
          :style="{ backgroundImage: 'url(' + src + ')' }"
        ></div>
      </div>
      <div class="text-center mt-3">
        <b-button variant="outline-primary" size="sm" @click="$emit('remove')">
          Remove {{ title }}
        </b-button>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "ImageField",
  props: {
    src: { type: String },
    title: { type: String, default: "Image" },
    vendorKey: { type: String },
    kind: { type: String, default: "" },
    rounded: { type: Boolean }
  },
  data() {
    return {
      uploading: false
    };
  },
  methods: {
    setDefaultSize({ imageSize }) {
      return { width: imageSize.width, height: imageSize.height };
    },
    selectedFile() {
      this.$refs.uploadBtn.click();
    },
    uploadImage() {
      const formData = new FormData(this.$refs.imageUploadForm);

      this.uploading = true;

      this.$store
        .dispatch("uploadImage", formData)
        .then(
          d => {
            this.$emit("uploaded", d);
          },
          e => {
            this.$emit("upload-error", e);
          }
        )
        .finally(() => (this.uploading = false));
    }
  }
};
</script>

<style scoped lang="scss">
img {
  max-height: 150px;
}

div.round {
  height: 140px;
  width: 140px;
  background-size: cover;
  background-position: 50%;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(black, 0.2);
}
</style>