<template>
  <div id="desc" class="platter mb-4 p-3" :data-editing="editing">
    <div class="actions float-right">
      <b-button
        v-if="!editing"
        size="sm"
        variant="outline-primary"
        @click="editing = true"
        v-b-tooltip.hover.v-dark
        title="Edit"
      >
        <font-awesome-icon icon="pencil-alt" fixed-width />
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="save"
      >
        <strong>Save</strong>
      </b-button>
      <b-button
        v-if="editing"
        size="sm"
        variant="outline-primary"
        @click="cancel"
      >
        Cancel
      </b-button>
    </div>
    <h3 class="mb-3">Description</h3>
    <b-row cols="1" class="p-1">
      <b-col v-if="editing || shortDesc">
        <vc-text-field-limit
          label="Short Description"
          v-model="shortDesc"
          @edit="editing = true"
          :editing="editing"
          :max-length="425"
          :rows="3"
          :max-rows="5"
          always-show-remaining
        />
      </b-col>
      <b-col v-if="editing || longDescTitle">
        <vc-text-field-limit
          label="Long Description Title"
          v-model="longDescTitle"
          @edit="editing = true"
          :editing="editing"
          :max-length="75"
          always-show-remaining
        />
      </b-col>
      <b-col v-if="editing || longDesc">
        <vc-text-field-limit
          label="Long Description"
          v-model="longDesc"
          @edit="editing = true"
          :editing="editing"
          :max-length="2000"
          always-show-remaining
          :rows="9"
          :max-rows="15"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "DescEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      editing: false,
      shortDesc: "",
      longDescTitle: "",
      longDesc: ""
    };
  },
  computed: {
    longDescHtml() {
      if (!this.longDesc) return undefined;
      return this.longDesc.replaceAll("\n", "<br>");
    }
  },
  watch: {
    vendor: "updateLocalData"
  },
  mounted() {
    this.$emit("add-nav", {
      href: "#desc",
      title: "Description"
    });

    this.updateLocalData();
  },
  methods: {
    updateLocalData() {
      this.shortDesc = this.vendor?.shortDesc;
      this.longDescTitle = this.vendor?.longDescTitle;
      this.longDesc = this.vendor?.longDesc;
    },
    save() {
      const data = {
        vendorKey: this.vendor?.key,
        shortDesc: this.shortDesc?.trim(),
        longDescTitle: this.longDescTitle?.trim(),
        longDesc: this.longDesc?.trim()
      };
      this.$store.dispatch("pushVendorChanges", data).then(
        d => {
          this.$emit("saved", d);
          this.editing = false;
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    cancel() {
      this.updateLocalData();
      this.editing = false;
    }
  }
};
</script>