<template>
  <div v-if="show" id="videos" class="platter mb-4 p-3">
    <div class="actions float-right">
      <b-button
        size="sm"
        variant="outline-primary"
        v-b-tooltip.hover.v-dark
        :title="'Add ' + itemName"
        @click="add"
      >
        <font-awesome-icon icon="plus" fixed-width />
      </b-button>
    </div>
    <h3 class="mb-3">{{ itemsName }}</h3>
    <vc-editor-table
      :items="items"
      :item-name="itemName"
      @edit="edit"
      @add="add"
      @sort="sort"
      @delete="deleteItem"
      :can-edit="false"
    />

    <b-modal
      id="video-item-modal"
      :title="modalModeLabel"
      :ok-disabled="!modalVideo"
      :ok-title="modalModeLabel"
      cancel-variant="link"
      no-fade
      centered
      @hide="selectedItem = undefined"
      @ok="save"
      @shown="$refs.videoUrl.focus()"
    >
      <b-form-group label="YouTube Video URL">
        <b-input v-model="modalVideoUrl" ref="videoUrl" />
      </b-form-group>

      <b-card
        v-if="modalVideo"
        body-class="d-flex align-items-center"
        body-bg-variant="light"
      >
        <b-row>
          <b-col cols="4">
            <b-img-lazy :src="modalVideo.thumbnailUrl" class="mr-3" fluid />
          </b-col>
          <b-col cols="8">
            <h5 class="mb-0">{{ modalVideo.title }}</h5>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import Enumerable from "linq";
import { getVideoSnippets } from "@/utils/youTubeDataApi";

export default {
  name: "VideosEditor",
  props: {
    vendor: { type: Object }
  },
  data() {
    return {
      show: true,
      selectedItem: undefined,
      modalVideoUrl: undefined,
      modalVideo: undefined
    };
  },
  watch: {
    modalVideoUrl() {
      getVideoSnippets([this.modalVideoUrl]).then(d => {
        this.modalVideo = d ? d[0] : undefined;
      });
    },
    selectedItem() {
      this.modalVideoUrl = this.selectedItem?.videoUrl;
      if (!this.selectedItem) this.modalVideo = undefined;
    }
  },
  computed: {
    itemName() {
      return "Video";
    },
    itemsName() {
      return `${this.itemName}s`;
    },
    items() {
      return Enumerable.from(this.vendor?.videos)
        .select(i => {
          return {
            thumbSrc: i.posterSrc,
            title: i.title,
            item: i
          };
        })
        .toArray();
    },
    modalModeLabel() {
      return `${!this.selectedItem ? "Add" : "Edit"} ${this.itemName}`;
    },
    selectedItemIndex() {
      return this.items.findIndex(i => i.item === this.selectedItem);
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#videos",
        title: this.itemsName
      });
    }
  },
  methods: {
    add() {
      this.selectedItem = undefined;
      this.$bvModal.show("video-item-modal");
    },
    edit(i) {
      this.selectedItem = i.item;
      this.$bvModal.show("video-item-modal");
    },
    save() {
      const data = {
        video: {
          sourceUrl:
            "https://www.youtube.com/embed/" + this.modalVideo.id + "?rel=0",
          title: this.modalVideo.title,
          posterSrc: this.modalVideo.thumbnailUrl
        },
        videoIndex: -1
      };

      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.modalVideo = undefined;
          this.modalVideoUrl = undefined;
          this.$emit("saved", d);
        },
        e => {
          this.$emit("save-error", e);
        }
      );
    },
    sort(d) {
      const data = {
        videosSort: d
      };
      this.pushChange(
        data,
        d => {
          this.$emit("sorted", d);
        },
        e => {
          this.$emit("sort-error", e);
        }
      );
    },
    deleteItem(i) {
      this.selectedItem = i.item;

      const data = {
        videoDelete: this.selectedItemIndex
      };
      this.pushChange(
        data,
        d => {
          this.selectedItem = undefined;
          this.$emit("deleted", d);
        },
        e => {
          this.$emit("delete-error", e);
        }
      );
    },
    pushChange(data, s, e) {
      this.$store
        .dispatch("pushVendorChanges", { vendorKey: this.vendor?.key, ...data })
        .then(s, e);
    }
  }
};
</script>